import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useCallback } from 'react';


export const useToken = () => {
  const { instance, accounts } = useMsal();

  const generateToken = useCallback(async () => {

    if (!instance.getActiveAccount() && instance.getAllAccounts().length) {
      const accounts = instance.getAllAccounts();
      instance.setActiveAccount(accounts[0]);
    }

    let scope = process.env.REACT_APP_MSAL_APPLICATION_OLD_SCOPE;
    const request = {
      scopes: scope?.split(',') || [],
      account: accounts[0],
    };
    try {
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const response = await instance.acquireTokenPopup(request);
        return response.accessToken;
      } else {
        console.error('Error acquiring token:', error);
        throw error;
      }
    }
  }, [instance, accounts]);
  
  return { generateToken };
};