import React, { useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { AuthenticationResult, PopupRequest } from "@azure/msal-browser";
import { AppDispatch } from "../store";
import { login } from "../features/auth/authSlice";
import { store } from "../store";
import { showNotification } from "../features/notification/notificationSlice";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useToken } from '../hooks/useToken';

const SignInButton: React.FC = () => {


  // eslint-disable-next-line react-hooks/exhaustive-deps


  const { instance, inProgress } = useMsal();
  const dispatch = useDispatch<AppDispatch>();

  const { isLogout } = useSelector((state: RootState) => state.auth);
  const { generateToken } = useToken();

  const handleLogin = useCallback((): void => {

    const paramsObject: any = {};
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.forEach((value, key) => {
      paramsObject[key] = value;
    });
    const loginRequest: PopupRequest = {
      scopes: [process.env.REACT_APP_MSAL_APPLICATION_SCOPE!],
    };

    instance
      .loginPopup(loginRequest)
      .then((response: AuthenticationResult) => {
        const handleLogin = async (response) => {
          try {
            const newScopeToken = await generateToken();
            localStorage.setItem('msalaccesstoken', newScopeToken);

            const formData = {
              token: response.accessToken,
              ...paramsObject,
            };
            await dispatch(login(formData)).unwrap();
          } catch (error) {
            if (typeof error === 'string') {
              store.dispatch(showNotification({ message: error, type: "error" }));
            }
          }
        };
        handleLogin(response);
      })
      .catch((error: Error) => {
        console.error("Login failed:", error);
      });
  }, [dispatch, instance, generateToken]);

  useEffect(() => {

    if (inProgress !== "none") return;
    if (isLogout !== true) {
      handleLogin();
    }
  }, [dispatch, instance, handleLogin, inProgress, isLogout]);
  return (
    <div className="checkbox">
      <button className="btn-signin" onClick={handleLogin}>
        Sign In With Microsoft
      </button>
    </div>
  );
};

export default SignInButton;
